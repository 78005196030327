import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Page from '../../components/molecules/page'

import { useLocation, useNavigate, } from 'react-router-dom'

import { CustomerNode } from '../../state/reducers/customer'
import { Alarm, Edit, Info, ReceiptOutlined,  VisibilityOff } from '@mui/icons-material'
import { SalesPerStore } from '../customers/salesGraph'
import { fetchCustomers } from '../../state/actions/customer'
import { SalesPerRecipeTable } from './salesTable'
import { Box, Divider } from '@mui/material'
import { ErrorsAndWarnings } from '../customers/errorsGraph/totalErrorsAndWarnings'

export const Reports = () => {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(fetchCustomers())
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
  const navigate = useNavigate()

  const dimensions = useSelector((state:any)=> state.dimensions)
  
  //let { customerId } = useParams(); // top node in node tree
  let { pathname } = useLocation()

  const nodeIds = pathname?.split('/')?.filter(x => x !== '' && x !== 'customers') ?? []
  const currentNodeId = nodeIds?.length ? nodeIds[nodeIds.length - 1] : null // current node in node tree

  const [customer, setCustomer] = useState<CustomerNode | undefined>(undefined)
  const customerParentHidden = customer?.parents?.find(p => p.hidden) ? true : false

  const [unhideCustomerDialogOpen, setUnhideCustomerDialogOpen] = useState<boolean>(false)
  const [editNameModalOpen, setEditNameModalOpen] = useState<boolean>(false)
  const [editInvoiceNumberOpen, setEditInvoiceNumberOpen] = useState<boolean>(false)


  return (
    <Page
      sideScroll
      greyOut={customer?.hidden || customerParentHidden}
      hideBreadcrumbs={false}
      breadcrumbNames={customer?.parents}
      title={customer?.name ? customer.name + (customer?.hidden ? ' (hidden)' : '') : ''}
      titleIcon={customer?.hidden ? <VisibilityOff color='primary' fontSize='large' /> : undefined}
      Menu={{
        Id:'customer-page-menu',
        ButtonTitle:'Actions',
        MenuItems: [
        {
          Label:'Edit customer name',
          Icon: <Edit />,
          onClick: () => setEditNameModalOpen(true),
        },
        {
          Label:'Unhide',
          Enabled: customer?.hidden,
          onClick: () => setUnhideCustomerDialogOpen(true)
        },
        {
          Label:'Invoice customer number',
          Icon: <ReceiptOutlined/>,
          onClick: () => console.log("Click")
        }
      ]
      }}

      tabs={[
        {
          name: 'Summary',
          icon: <Info />,
          pageSections: [<SalesPerStore /> , <ErrorsAndWarnings />]
        },
        {
          name: 'Weekly',
          icon: <Alarm/>,
          pageSections: [<WeeklyReportSection/>]
        }
        // },
        // {
        //   name: 'Machine',
        //   icon: <Alarm/>,
        //   pageSections: [<MachineReportSection/>]
        // }
      ] }
    >
      
    </Page>
  )

}

const Summary = () => {
    return ( 
    <Box>
        <SalesPerStore />
        <Divider></Divider>
        <ErrorsAndWarnings />
    </Box> )
}

const WeeklyReportSection = () => {
    return ( 
        <Box>
          <SalesPerRecipeTable  />
        </Box>
    )
} 

// const MachineReportSection = () => {
//   return(
//     <Box>
//       <MachineLogTable />
//     </Box>
//   )
// }