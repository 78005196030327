export const compareVersions = (a, b) =>  {
    let aParts
    let bParts
    if(a.softwareVersion && b.softwareVersion){
      aParts = a.softwareVersion?.split('.').map(Number);
      bParts = b.softwareVersion?.split('.').map(Number);
    }else{
      aParts = a.split('.').map(Number);
      bParts = b.split('.').map(Number);
    }
  
  
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aVal = aParts[i] || 0;  // Default to 0 if part is missing
        const bVal = bParts[i] || 0;
        if (aVal !== bVal) {
            return aVal - bVal;
        }
    }
    return 0; 
  }