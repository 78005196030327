import { SortOn } from '../../pages/machines/logTable'
import MachineActionTypes, { MACHINE_STATE_CHANGED_RECEIVED, RECIPE_ACTION_MESSAGE_RECEIVED, FETCH_MACHINES, FETCH_MACHINE_LOGS, FETCH_MACHINE_ONLINE_HISTORY, FETCH_ORPHANED_MACHINES, RENAME_MACHINE, FETCH_MACHINES_WITH_ALERT_TYPES } from '../actionTypes/machine'

export const fetchMachines = (prop?: string) : MachineActionTypes => ({
  type: FETCH_MACHINES,
  prop
})

export const fetchMachinesWithAlertTypes = () : MachineActionTypes => ({
  type: FETCH_MACHINES_WITH_ALERT_TYPES
})

export const testMachineConnected = (message) : MachineActionTypes => ({
  type: MACHINE_STATE_CHANGED_RECEIVED,
  message
})

export const testRecipe = (message) : MachineActionTypes => ({
  type: RECIPE_ACTION_MESSAGE_RECEIVED,
  message
})

export const fetchOrphanedMachines = () : MachineActionTypes => ({
  type: FETCH_ORPHANED_MACHINES
})

// export const fetchMachineTwin = (serialId: string) : MachineActionTypes => ({
//   type: FETCH_MACHINE_TWIN,
//   serialId
// })

export const renameMachine = (serialId: string, newName: string) : MachineActionTypes => ({
  type: RENAME_MACHINE,
  serialId,
  newName
})

export const fetchMachineLogs = (
  serialId: string,
  sortBy?: SortOn,
  desc?: boolean,
  countPerPage?: number,
  page?: number,
  nameFilter?: string,
  start?: number,
  end?: number) : MachineActionTypes => ({
  type: FETCH_MACHINE_LOGS,
  serialId,
  sortBy,
  desc,
  start,
  end,
  countPerPage,
  page,
  nameFilter
})

export const fetchMachineOnlineHistory = (
  serialId: string) : MachineActionTypes => ({
  type: FETCH_MACHINE_ONLINE_HISTORY,
  serialId
})
