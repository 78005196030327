import { createAsyncThunk } from "@reduxjs/toolkit"
import { FetchFromBackoffice } from "../../utils/request"
import { IPagedResponse } from "../../common-types"
import { PostToBackoffice } from "../../utils/commands"
import { isProd } from "../../config"
import { useDispatch } from "react-redux"
import { updateReducerWithThunk } from "../reducerUtils"

export type RecipeItem = {
    transactionId:string,
    serialNumber:string,
    machineName:string,
    startedAt: string,
    endedAt: string | undefined,
    status: string,
    recipeName: string,
    excludedFromInvoicing: boolean,
    excludedFromInvoicingReason: string | undefined,
}

export const fetchRecipesForMachine = createAsyncThunk(
    "FETCH_MACHINE_RECIPES",
    async ({machineId, paging, count}:{machineId: string, paging: number, count: number}) => {
    const response  = await 
        FetchFromBackoffice<IPagedResponse<RecipeItem>>(`machines/${machineId}/recipes?page=${paging}&count=${count}`)

        return response 
    },
  )
  export const setRecipeAsIncludedinInvoicing = createAsyncThunk(
    "SET_RECIPE_AS_INCLUDED",
    async ({machineId, transactionId}:{machineId: string, transactionId: string}) => {
    const response  = await PostToBackoffice('cmd/machine/include/run/invoice', {
        id: machineId,
        transactionId: transactionId,
    })
    return response
    },
  )

  export const postExcludeFromInvoicing = createAsyncThunk(
    "SET_RECIPE_AS_EXCLUDED",
    async ({id, transactionId, reason}:{id: string, transactionId: string,reason: string }) => {
    const response = await  PostToBackoffice('cmd/machine/exclude/run/invoice', {
        id: id,
        transactionId: transactionId,
        reason: reason,
    })
    return response
})


export const setMachineAsActive = createAsyncThunk(
    "SET_MACHINE_TO_ACTIVE",
    async ({serialNumber}:{serialNumber:string}) => {
         const response = await  PostToBackoffice('cmd/machine/monitor/start', {
            id: serialNumber,
        })
        return response
    })

export const deactivateMachine = createAsyncThunk(
    "DEACTIVATE_MACHINE",
    async ({serialNumber }:{serialNumber:string}) => {
         const response = await  PostToBackoffice('cmd/machine/monitor/stop', {
            id: serialNumber,
        })

        return response
    })