import React, { Dispatch } from 'react'
import { Box, Checkbox } from "@mui/material";
import Text from '../atoms/text/text';

export const LabledCheckbox = (
    {
        isChecked, 
        setIsChecked, 
        lable

    }:
    {
        isChecked: boolean | undefined, 
        setIsChecked: Dispatch<React.SetStateAction<boolean | undefined>>,
        lable: string
    }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Checkbox
        checked={isChecked}
        onClick={() => {
          setIsChecked(!isChecked);
        }}
      />
      <Text>{lable}</Text>
    </Box>
  );
};
