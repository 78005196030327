import { takeEvery } from 'redux-saga/effects'
import { postUpdatedMachineName } from '../../utils/commands'
import { fetchMachines, fetchMachineLogs, fetchOrphanedMachines, fetchMachineOnlineHistory, fetchMachinesWithAlertTypes } from '../../utils/request'
import { FETCH_MACHINES, FETCH_MACHINES_WITH_ALERT_TYPES, FETCH_MACHINE_LOGS, FETCH_MACHINE_ONLINE_HISTORY, FETCH_ORPHANED_MACHINES, RENAME_MACHINE } from '../actionTypes/machine'

export function* machineSagas() {
  yield takeEvery(FETCH_MACHINES, fetchMachines)
  yield takeEvery(FETCH_MACHINES_WITH_ALERT_TYPES, fetchMachinesWithAlertTypes)
  yield takeEvery(FETCH_MACHINE_ONLINE_HISTORY, fetchMachineOnlineHistory)
  // yield takeEvery(FETCH_MACHINE_TWIN, fetchMachineTwin)
  yield takeEvery(FETCH_MACHINE_LOGS, fetchMachineLogs)
  yield takeEvery(FETCH_ORPHANED_MACHINES, fetchOrphanedMachines)
  yield takeEvery(RENAME_MACHINE, postUpdatedMachineName)
}