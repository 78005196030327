import { Action } from 'redux'
import { SortOn } from '../../pages/machines/logTable'
import { MachineLog, MachineReducer, MachineState, MachineOnlineState } from '../reducers/machine'

export const FETCH_MACHINES = 'FETCH_MACHINES'
export interface FetchMachines extends Action {
  type: typeof FETCH_MACHINES
  prop?: "dashboard_view"
}
export const FETCH_MACHINES_WITH_ALERT_TYPES = 'FETCH_MACHINES_WITH_ALERT_TYPES'
export interface FetchMachinesWithAlertTypes extends Action {
  type: typeof FETCH_MACHINES_WITH_ALERT_TYPES
}
interface MachinesFetched extends Action {
  type: 'MACHINES_FETCHED'
  data: MachineReducer
}

interface FetchingMachinesFailed extends Action {
  type: 'FETCHING_MACHINES_FAILED'
  status: number
  statusText: string
}
interface FetchingMachineOnlineHistoryFailed extends Action {
  type: 'FETCHING_MACHINE_ONLINE_HISTORY_FAILED'
  status: number
  statusText: string
}

// export const FETCH_MACHINE_TWIN = 'FETCH_MACHINE_TWIN'
// export interface FetchMachineTwin extends Action {
//   type: typeof FETCH_MACHINE_TWIN
//   serialId: string
// }

// interface MachineTwinFetched extends Action {
//   type: 'MACHINE_TWIN_FETCHED'
//   serialId: string
//   data: unknown
// }

// interface FetchingMachineTwinFailed extends Action {
//   type: 'FETCHING_MACHINE_TWIN_FAILED'
//   status: number
//   statusText: string
// }

export const FETCH_MACHINE_ONLINE_HISTORY = 'FETCH_MACHINE_ONLINE_HISTORY'
export interface FetchMachineOnlineHistory extends Action {
  type: typeof FETCH_MACHINE_ONLINE_HISTORY
  serialId: string
}

export type MachineOnlineHistoryType = {
  id: string
  machineName: string,
  createdAt: string,
  // nodeId: string,
  // nodeName: string,
  // levelName: string,
  items: MachineOnlineState[]
}

interface MachineOnlineHistoryFetched extends Action {
  type: 'MACHINE_ONLINE_HISTORY_FETCHED'
  data: MachineOnlineHistoryType
}

export const FETCH_MACHINE_LOGS = 'FETCH_MACHINE_LOGS'

export interface FetchMachineLogs extends Action {
  type: typeof FETCH_MACHINE_LOGS
  serialId: string
  sortBy?: SortOn
  desc?: boolean
  start?: number
  end?: number
  countPerPage?: number
  page?: number
  nameFilter?: string
}

interface MachineLogsFetched extends Action {
  type: 'MACHINE_LOGS_FETCHED'
  serialId: string
  data: {
    records: MachineLog[]
    total: number
  }
}

interface FetchingMachineLogsFailed extends Action {
  type: 'FETCHING_MACHINE_LOGS_FAILED'
  status: number
  statusText: string
}

export const FETCH_ORPHANED_MACHINES = 'FETCH_ORPHANED_MACHINES'
export interface FetchOrphanedMachines extends Action {
  type: typeof FETCH_ORPHANED_MACHINES
}

interface OrphanedMachinesFetched extends Action {
  type: 'ORPHANED_MACHINES_FETCHED'
  data: MachineState[]
}

interface FetchingOrphanedMachinesFailed extends Action {
  type: 'FETCHING_ORPHANED_MACHINES_FAILED'
}

export const RENAME_MACHINE = 'RENAME_MACHINE'
export interface RenameMachine extends Action {
  type: typeof RENAME_MACHINE,
  serialId: string,
  newName: string
}

interface MachineRenamed extends Action {
  type: 'MACHINE_RENAMED'
  serialId: string,
  newName: string
}

interface RenamingMachineFailed extends Action {
  type: 'RENAMING_MACHINE_FAILED',
  serialId: string,
  newName: string
}

export const RECIPE_ACTION_MESSAGE_RECEIVED = 'RECIPE_ACTION_MESSAGE_RECEIVED'
interface ReceivedRecipeAction extends Action {
  type: 'RECIPE_ACTION_MESSAGE_RECEIVED',
  message: {
    machineId: string,
    action: string,
    nodeId: string,
    recipeType: string,
    timestamp: string
  }
}

//MACHINE_DEACTIVATED

interface MachineDeactivated extends Action {
  type: 'MACHINE_DEACTIVATED',
  payload
}

interface MachineActivated extends Action {
  type: 'MACHINE_ACTIVATED',
  payload
}
interface ReceivedMachineLogAction extends Action {
  type: 'MACHINE_LOG_MESSAGE_RECEIVED',
  message: {
    machineId: string,
    timestamp: string,
    level: string,
    message: string,
    source: string
  }
}

export const MACHINE_STATE_CHANGED_RECEIVED = 'MACHINE_STATE_CHANGED_RECEIVED'
interface ReceivedMachineStateChangedAction extends Action {
  type: 'MACHINE_STATE_CHANGED_RECEIVED',
  message: {
    machineId: string,
    currentState: string,
    timestamp: string
  }
}

type MachineActionTypes =
  | FetchMachines
  | FetchMachinesWithAlertTypes
  | MachinesFetched
  | FetchingMachinesFailed
  // | FetchMachineTwin
  // | MachineTwinFetched
  // | FetchingMachineTwinFailed
  | FetchMachineOnlineHistory
  | FetchingMachineOnlineHistoryFailed
  | MachineOnlineHistoryFetched
  | FetchMachineLogs
  | MachineLogsFetched
  | FetchingMachineLogsFailed
  | FetchOrphanedMachines
  | OrphanedMachinesFetched
  | FetchingOrphanedMachinesFailed
  | FetchOrphanedMachines
  | RenameMachine
  | MachineRenamed
  | RenamingMachineFailed
  | ReceivedRecipeAction
  | ReceivedMachineLogAction
  | ReceivedMachineStateChangedAction
  | MachineDeactivated
  | MachineActivated

export default MachineActionTypes